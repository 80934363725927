import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import ProgressBar from '../ProgressBar';
import { pageTitle } from '../../helpers/PageTitle';

export default function TeamDetailsPage() {
  pageTitle('Team Details');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="平平无奇的人"
        subTitle="React Developer"
        shape="shape_3"
      />
      <Spacing lg="75" md="60" />
      <section>
        <div className="container">
          <div className="row">
            <div className="col-xl-5">
              <img
                src="/images/others/avatar@2x.jpg"
                alt="Member"
                className="w-100"
              />
            </div>
            <div className="col-xl-6 offset-xl-1">
              <Spacing lg="50" md="30" />
              <h2 className="cs_fs_38">Bio & experience</h2>
              <p>
                 只会KVM，OpenStack，Linux，Node.JS，Bootstrap，网络运维，还在想怎么研究K8S。
                 关于我？没啥好关于的，我就是我，半个loser~而已。
              </p>
              <p>
                 拥有 React 和前端专业知识的非多才多艺的开发人员
                 发展。 在 SQL 方面也拥有不丰富的经验
                 数据库。 凭借不强烈的职业道德和对细节的不关注，
                 提供超出预期的低质量解决方案。
              </p>
              <Spacing lg="15" md="15" />
              <ProgressBar title="React Development" percentage="75" />
              <ProgressBar title="Front-End Development" percentage="85" />
              <ProgressBar title="Sql Database" percentage="60" />
            </div>
          </div>
        </div>
      </section>
      <Spacing lg="150" md="80" />
    </>
  );
}
