import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import { pageTitle } from '../../helpers/PageTitle';

export default function BlogDetailsPage() {
  pageTitle('Blog Details');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="服务条款"
        variant="text-center"
        shape="shape_5"
        category="TERMS OF SERVICE"
        date="30 Mar 2024"
        avatar="TOS"
        avatarLink="/"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          src="/images/tos/ui4.png"
          alt="Blog"
          className="cs_radius_15"
        />
        </div>
        <div className="cs_height_60 cs_height_lg_40" />
        <div className="cs_post_details">
          <h3>
            来自其他网站的嵌入内容
          </h3>
          <p>此站点上的文章可能会包含嵌入的内容（如视频、图片、文章等）。来自其他站点的嵌入内容的行为和您直接访问这些其他站点没有区别。</p>
          <p>这些站点可能会收集关于您的数据、使用cookies、嵌入额外的第三方跟踪程序及监视您与这些嵌入内容的交互，包括在您有这些站点的账户并登录了这些站点时，跟踪您与嵌入内容的交互。</p>
          <h3>
            免责声明
          </h3>
          <p>此外，在使用本网站上的特定服务或材料时，用户应遵守任何适用于此类服务或材料的发布规则，这些规则可能包含除本服务条款之外的条款和条件。所有此类指南或规则均通过引用并入本服务条款。</p>
					<p>这些服务条款自[DATE]起生效。我们明确保留不时更改这些服务条款的权利，恕不另行通知。您承认并同意您有责任不时查看本网站和这些服务条款并熟悉任何修改。</p>
					<p>您在此类修改后继续使用本网站将构成对修改后的服务条款的确认，并同意遵守修改后的服务条款并受其约束。本服务条款由服务条款模板和条款和条件生成器创建。</p>
					<p>我们保留在通知或不通知您的情况下随时修改或终止本网站（包括本网站的任何功能）的唯一权利。如果我们行使该权利，我们将不对您或任何第三方承担任何责任。任何增加或增强本网站当前服务的新功能也应受这些服务条款的约束。</p>
          <div className="cs_height_10 cs_height_lg_5" />
          <h3>网站行为</h3>
          <p>您对本网站的使用受所有适用法律法规的约束，并且您对通过本网站进行的通信的实质内容负全部责任。通过在或以其他方式使用任何通信服务、聊天室、留言板、新闻组、软件库或您在本网站上或通过本网站可用的其他互动服务中发布信息，您同意您不会上传、分享、发布、或以其他方式分发或促进分发任何内容（包括文本、通信、软件、图像、声音、数据或其他信息）</p>
					<p>是非法的、威胁性的、辱骂性的、骚扰性的、诽谤性的、诽谤性的、欺骗性的、欺诈性的、侵犯他人隐私的、侵权的、包含对性行为的明确或图形描述或描述（包括但不限于针对暴力或威胁性质的性语言）其他个人或团体），或以其他方式违反我们的规则或政策</p>
					<p>基于宗教、性别、性取向、种族、民族、年龄或残疾对个人或个人群体进行伤害、骚扰、贬低或恐吓</p>
					<p>侵犯任何一方的任何专利、商标、商业秘密、版权、公开权或其他专有权利</p>
					<p>构成未经授权或未经请求的广告、垃圾或大量电子邮件（也称为“垃圾邮件”）、连锁信、任何其他形式的未经授权的招揽或任何形式的彩票或赌博</p>
					<p>包含软件病毒或任何其他计算机代码、文件或程序，旨在或旨在破坏、损坏或限制任何软件、硬件或电信设备的功能，或损坏或未经授权访问任何数据或其他信息任何第三方</p>
					<p>冒充任何个人或实体，包括我们的任何员工或代表</p>
					<p>对于本网站第三方用户上传或提交的任何材料的内容，我们既不认可也不承担任何责任。我们通常不会预先筛选、监控或编辑通信服务、聊天室、留言板、新闻组、软件库或可能在本网站上或通过本网站提供的其他交互式服务的用户发布的内容。但是，我们和我们的代理有权自行决定删除我们认为不符合本服务条款和我们网站的任何其他用户行为规则的任何内容，或者在其他方面有害、令人反感或不准确。对于删除此类内容的任何失败或延迟，我们概不负责。您在此同意此类删除，并放弃因此类删除内容而对我们提出的任何索赔。</p>
          <h3>终止</h3>
          <p>您同意我们可以自行决定终止或暂停您对本网站的全部或部分的访问，无论是否通知，出于任何原因，包括但不限于违反这些服务条款。任何涉嫌欺诈、滥用或非法活动都可能成为终止您的关系的理由，并可能被提交给适当的执法机构。</p>
					<p>在终止或暂停后，无论出于何种原因，您使用本网站提供的服务的权利将立即停止，并且您承认并同意我们可以立即停用或删除您的帐户以及您帐户中的所有相关信息和文件和/或禁止进一步访问此类文件或本网站。对于因任何终止或暂停或我们就此类终止或暂停采取的任何其他行动而引起的任何索赔或损害，我们不对您或任何第三方承担责任。</p>
          <blockquote>
          <p>有任何问题请用电子邮箱进行联系。</p>
					<p>bnuz@mail.io</p>
            <small>非常重要通知</small>
          </blockquote>
          <ul>
            <li>任何数据都遵循 GDPR《通用数据保护条例》 </li>
            <li>2024.03.30 已更新服务条款和隐私政策 </li>
          </ul>
        </div>
        <div className="cs_height_60 cs_height_lg_40" />
      </div>
    </>
  );
}
