import React from 'react';
import Spacing from '../Spacing';
import SectionHeadingStyle3 from '../SectionHeading/SectionHeadingStyle3';
import { pageTitle } from '../../helpers/PageTitle';

export default function BlogDetailsPage() {
  pageTitle('Blog Details');
  return (
    <>
      <Spacing lg="70" md="70" />
      <Spacing lg="140" md="80" />
      <SectionHeadingStyle3
        title="隐私政策"
        variant="text-center"
        shape="shape_5"
        category="Privacy Policy"
        date="30 Mar 2024"
        avatar="Privacy Policy"
        avatarLink="/"
      />
      <Spacing lg="75" md="60" />
      <div className="container">
        <div style={{ display: 'flex', justifyContent: 'center' }}>
        <img
          src="/images/tos/ui4.png"
          alt="Blog"
          className="cs_radius_15"
        />
        </div>
        <div className="cs_height_60 cs_height_lg_40" />
        <div className="cs_post_details">
          <h3>
            信息收集和使用
          </h3>
          <p>为了在使用我们的服务时获得更好的体验，我们可能会要求您向我们提供某些个人身份信息，包括但不限于您的姓名、电话号码和邮政地址。我们收集的信息将用于联系或识别您。</p>
          <p>这些站点可能会收集关于您的数据、使用cookies、嵌入额外的第三方跟踪程序及监视您与这些嵌入内容的交互，包括在您有这些站点的账户并登录了这些站点时，跟踪您与嵌入内容的交互。</p>
          <h3>
            记录数据
          </h3>
          <p>我们想通知您，每当您访问我们的服务时，我们都会收集您的浏览器发送给我们的称为日志数据的信息。此日志数据可能包括您的计算机的 Internet 协议（“IP”）地址、浏览器版本、您访问的我们服务的页面、您访问的时间和日期、在这些页面上花费的时间以及其他统计数据等信息。</p>
          <div className="cs_height_10 cs_height_lg_5" />
          <h3>Cookies</h3>
          <p>Cookie 是具有少量数据的文件，通常用作匿名唯一标识符。这些信息从您访问的网站发送到您的浏览器，并存储在您的计算机硬盘上。</p>
					<p>我们的网站使用这些“cookies”来收集信息并改进我们的服务。您可以选择接受或拒绝这些 cookie，并知道何时将 cookie 发送到您的计算机。如果您选择拒绝我们的 cookie，您可能无法使用我们服务的某些部分。</p>
          <h3>安全</h3>
          <p>我们重视您对向我们提供您的个人信息的信任，因此我们正在努力使用商业加密上可接受的方式来保护它。</p>
          <h3>FAQ</h3>
          <p>我们不会链接任何第三方网站进行交互，里面任何内容都会进行加密处理。</p>
					<p>如果访问和注册账户即同意隐私政策访问，如不同意可以手动到后台去进行删除账号。</p>
          <h3>本隐私政策的变更</h3>
          <p>我们可能会不时更新我们的隐私政策。因此，我们建议您定期查看此页面以了解任何更改。我们将通过在此页面上发布新的隐私政策来通知您任何更改。这些更改在发布到此页面后立即生效。</p>
          <blockquote>
          <p>有任何问题请用电子邮箱进行联系。</p>
					<p>bnuz@mail.io</p>
            <small>非常重要通知</small>
          </blockquote>
          <ul>
            <li>任何数据都遵循 GDPR《通用数据保护条例》 </li>
            <li>2024.03.30 已更新服务条款和隐私政策 </li>
          </ul>
        </div>
        <div className="cs_height_60 cs_height_lg_40" />
      </div>
    </>
  );
}
