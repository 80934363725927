import React from 'react';
import Hero from '../Hero';
import Spacing from '../Spacing';
import FunFact from '../FunFact';
import About from '../About';
import WhyChose from '../WhyChose';
import Portfolio from '../Portfolio';
import Button from '../Button';
import SectionHeading from '../SectionHeading';
import Accordion from '../Accordion';
import Cta from '../Cta';
import IconBoxStyle2 from '../IconBox/IconBoxStyle2';
import { pageTitle } from '../../helpers/PageTitle';
const funfactData = [
  { title: '创办于', number: '1902年' },
  { title: '余亩', number: '5000' },
  { title: '人口', number: '近3万' },
  { title: '发展', number: '十多年' },
];
const whyChoseFeatureData = [
  {
    title: '前端页面加密',
    content:
      '使用 TLC ECC 加密数据，防止数据破解。',
  },
  {
    title: '服务器级加密储存',
    content:
      '使用 CEPH 储存，无任何人访问内部数据，并每天进行自动备份。',
  },
  {
    title: '遵循 GDPR《通用数据保护条例》',
    content:
      '对于数据会遵循 GDPR ，并对数据处理进行保密。',
  },
];
const portfolioData = [
  {
    href: 'https://forum.bnuz.xyz/',
    imgUrl: '/images/creative-agency/ni2.png',
    title: 'BNUZ Forum',
    btnText: 'See Project',
  },
  {
    imgUrl: '/images/creative-agency/ni6.png',
    title: 'S3 Projects Space',
    btnText: 'BUILDING',
  },
];
const faqData = [
  {
    title: '01. 论坛注册需要什么信息？',
    content:
      'A: 通常需要一个有效的邮箱地址、一个用户名和密码。可能还会要求验证手机号或回答安全问题。',
  },
  {
    title: '02. 我如何在论坛上发帖？',
    content:
      '首先，确保你已经登录。然后，找到相应的版块或主题，点击“新主题”或“发帖”按钮，输入你的内容，最后点击“提交”或“发表”。',
  },
  {
    title: '03. 如果我忘记了密码，该怎么办？',
    content:
      '在登录页面，通常会有一个“忘记密码”或“重置密码”的链接。点击它并按照指示操作，通常是输入你的邮箱地址来接收重置密码的链接。',
  },
  {
    title: '04. 我可以更改我的用户名吗？',
    content:
      '注册时候会要求输入用户名，一旦确认了用户名，就不能更改用户名。',
  },
  {
    title: '05. 你们是官方网站吗？',
    content:
      '不，我们不是官方网站，我们只是乐趣做这些事情，和官方网站并没有任何联系。',
  },
  {
    title: '06. 我如何报告不当内容或滥用行为？',
    content:
      '如果你看到不当内容或滥用行为，可以通过点击帖子旁边的“举报”按钮来报告。提供必要的信息，管理员会对此进行审查。',
  },
]; 

export default function Home() {
  pageTitle('BNUZ');
  return (
    <>
      <Hero
        title={[
          'Beijing Normal University ZhuHai',
          '“爱国进步、诚信质朴、求真创新、为人师表”',
          '“学为人师，行为世范”',
          '“治学修身，兼济天下”',
        ]}
        subtitle="北京师范大学珠海校区"
        videoSrc="https://pub-ce9bbeffdba54067b878aa4fcd6657cc.r2.dev/bnuz.mp4"
        bgUrl="/images/creative-agency/bnuz.jpg"
      />
      <Spacing lg="125" md="70" />
      <div className="container">
        <FunFact data={funfactData} />
      </div>
      <Spacing lg="125" md="70" />
      <About
        thumbnail="/images/creative-agency/3d1.png"
        uperTitle="Who We Are"
        title="是提供给北师珠校园内的基础服务。"
        subTitle="我们提供了论坛服务，并提供更详细的文档支持，无忧无虑。"
        featureList={[
          '无收集任何信息和内容',
          '分享日常、提高学习氛围',
          '仅校内相互交流',
        ]}
        btnText="跳转到论坛"
        btnUrl="https://forum.bnuz.xyz/"
        secondBtnText="APP使用教程"
        secondBtnUrl="https://docs.bnuz.xyz/"
      />
      <Spacing lg="185" md="75" />
      <WhyChose
        sectionTitle="我们拥有技术维护"
        sectionSubTitle="Why Choose Us"
        whyChoseFeatureData={whyChoseFeatureData}
        thumbnailSrc="/images/creative-agency/3d10.png"
      />
      <Spacing lg="150" md="80" />
      <section className="cs_primary_bg">
        <section className="cs_primary_bg">
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading
            title="我们的 <br />Service Product"
            subTitle="Services"
            variantColor="cs_white_color"
          />
          <Spacing lg="85" md="45" />
          <div className="row cs_gap_y_45">
            <div className="col-lg-3 col-sm-6">
              <IconBoxStyle2
                iconSrc="/images/digital-agency/service_icon_1.svg"
                title="Brand Stratege"
                subTitle="Brand's strategy and insights are a forward-thinking blueprint for success."
                features={[
                  'Business Development',
                  'Research & Branding',
                  'Strategy Services',
                  'Business Consulting',
                  'Idea Generate',
                  'Search Engine Optimize',
                ]}
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <IconBoxStyle2
                iconSrc="/images/digital-agency/service_icon_2.svg"
                title="UI/UX Design"
                subTitle="Help reinforce your brand's identity & cultivate positive user behaviors."
                features={[
                  'UX Research',
                  'Trend Analysis',
                  'A/B Testing',
                  'Information Architecture',
                  'Mockup Design',
                  'Color Analysis',
                ]}
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <IconBoxStyle2
                iconSrc="/images/digital-agency/service_icon_3.svg"
                title="Animation"
                subTitle="Bringing stories to life the power of modern age animation."
                features={[
                  'Idea Generate',
                  'Story Writing',
                  'White Board Animation',
                  'Advertise Animation',
                  'Video Editing',
                ]}
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <IconBoxStyle2
                iconSrc="/images/digital-agency/service_icon_4.svg"
                title="Web Development"
                subTitle="Exploring the World of Web Development in zivan digital agency."
                features={[
                  'UI/UX Design',
                  'React Application',
                  'eCommerce Development',
                  'Business Website',
                  'App Development',
                  'Web Application',
                ]}
              />
            </div>
          </div>
        </div>
        <Spacing lg="143" md="75" />
      </section>
      </section>
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="我们的项目" subTitle="Future Projects" />
          <Spacing lg="85" md="45" />
          <Portfolio data={portfolioData} />
          <Spacing lg="26" md="30" />
          <div className="text-center">
            <Button btnText="See All Project" btnUrl="/" />
          </div>
        </div>
        <Spacing lg="143" md="75" />
      </section>
      <section>
        <div className="container">
          <Cta
            title="想要加入我们的项目？"
            btnText="联系我们"
            btnUrl="/contact"
            bgUrl="/images/creative-agency/cta_bg.jpeg"
          />
        </div>
      </section>
      <section>
        <Spacing lg="143" md="75" />
        <div className="container">
          <SectionHeading title="Frequently asked question" subTitle="FAQs" />
          <Spacing lg="55" md="30" />
          <div className="row">
            <div className="col-lg-10 offset-lg-1">
              <Accordion variant="cs_type_1" data={faqData} />
            </div>
          </div>
        </div>
        <Spacing lg="120" md="50" />
      </section>
    </>
  );
}
